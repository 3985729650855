.block {
  margin: 17rem 0;
}

.block__title {
  margin: 0 0 5rem;
  font-weight: 500;
  font-size: 4rem;
}

.block__title__cursor {
  color: #FFF;
}

.block__paragraph {
  line-height: 1.7em;
  font-weight: 300;
  font-size: 3rem;
}

.block__links {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 5rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.block__links__link {
  font-size: 2.3rem;
  white-space: nowrap;
}

.block__links__link a {
  text-decoration: inherit;
  color: inherit;
}

.block__links__link a:hover span {
  text-decoration: underline;
}

.block__links__link i {
  font-style: inherit;
}

@media (max-width: 1280px) {
  .block {
    margin: 8rem 0;
  }

  .block__title {
    font-size: 3rem;
  }

  .block__paragraph {
    font-size: 2rem;
  }

  .block__links {
    margin: 5rem 0 0;
  }

  .block__links__link {
    font-size: 1.8rem;
  }
}

@media (max-width: 640px) {
  .block {
    margin: 4rem 0;
  }

  .block__title {
    margin: 0 0 3rem;
    font-size: 2.2rem;
  }

  .block__paragraph {
    font-size: 1.4rem;
  }

  .block__links {
    display: block;
    margin: 3rem 0 0;
  }

  .block__links__link {
    margin: 2.5rem 0;
    font-size: 1.3rem;
  }

  .block__links__link:first-child {
    margin-top: 0;
  }

  .block__links__link:last-child {
    margin-bottom: 0;
  }
}