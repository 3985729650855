@import-normalize;

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Code';
  font-weight: 400;
  font-size: 10px;
}

body {
  background: #33228F;
  font-size: 1rem;
  color: #FFF;
}

.app {
  padding: 17rem 23rem;
}

@media (max-width: 1280px) {
  .app {
    padding: 12rem;
  }
}

@media (max-width: 640px) {
  .app {
    padding: 4rem;
  }

  .app__header .logo svg {
    width: 66%;
    height: auto;
  }
}